@import "variables";
@import "breakpoints";
@import "grid-framework";
@import "grid";
@import "mixins";
*,
:after,
:before {
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: Apple-System, Arial, Helvetica, PingFang SC, Hiragino Sans GB,
    Microsoft YaHei, STXihei, sans-serif;
  font-size: 14px;
  line-height: 1.42857143;
  color: #5a5a5a;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @apply leading-normal font-semibold;
}

h1 {
  font-size: rem(30);
}
h2 {
  font-size: rem(26);
}
h3 {
  font-size: rem(22);
}
h4 {
  font-size: rem(19);
}
h5 {
  font-size: rem(15);
}
h6 {
  font-size: rem(12);
}

a {
  @apply text-primary;
}

// Container widths
//
// Set the container width, and override it for fixed navbars in media queries.

@if $enable-grid-classes {
  // Single container class with breakpoint max-widths
  .container,
  // 100% wide container at all breakpoints
  .container-fluid {
    @include make-container();
  }

  // Responsive containers that are 100% wide until a breakpoint
  @each $breakpoint, $container-max-width in $container-max-widths {
    .container-#{$breakpoint} {
      @extend .container-fluid;
    }

    @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
      %responsive-container-#{$breakpoint} {
        max-width: $container-max-width;
      }

      // Extend each breakpoint which is smaller or equal to the current breakpoint
      $extend-breakpoint: true;

      @each $name, $width in $grid-breakpoints {
        @if ($extend-breakpoint) {
          .container#{breakpoint-infix($name, $grid-breakpoints)} {
            @extend %responsive-container-#{$breakpoint};
          }

          // Once the current breakpoint is reached, stop extending
          @if ($breakpoint == $name) {
            $extend-breakpoint: false;
          }
        }
      }
    }
  }
}

// Row
//
// Rows contain your columns.

@if $enable-grid-classes {
  .row {
    @include make-row();
  }

  // Remove the negative margin from default .row, then the horizontal padding
  // from all immediate children columns (to prevent runaway style inheritance).
  .no-gutters {
    margin-right: 0;
    margin-left: 0;

    > .col,
    > [class*="col-"] {
      padding-right: 0;
      padding-left: 0;
    }
  }
}

// Columns
//
// Common styles for small and large grid columns

@if $enable-grid-classes {
  @include make-grid-columns();
}

.navbar {
  height: 30px;
  background-color: $primary;
}

.button {
  border-color: $primary;
  color: $white;
  background: $primary;
  padding: 12px 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  @apply border-2 rounded-sm;
  span {
    font-size: rem(24);
  }
  svg {
    width: 30px;
    height: 30px;
    margin-left: auto;
  }
  &.dense {
    padding: 6px 17px;
  }

  &:disabled {
    @apply opacity-50;
  }
  &.inverted {
    color: $primary;
    background-color: $white;
    svg {
      transform: rotate(180deg);
    }
  }
}

.justify-content-center {
  justify-content: center;
}

label::first-letter {
  @apply capitalize;
}

select,
input {
  @apply shadow-sm relative mb-2 appearance-none rounded-sm border p-3 text-gray-700 leading-tight focus:outline-none;
}

input[type="text"] {
  @apply rounded w-full;
}

input[type="radio"] {
  @apply w-6 h-6 relative rounded-full;
}

input[type="radio"]:checked:after {
  content: url("https://api.iconify.design/akar-icons/circle-check.svg?color=%23f44c4c");
  @apply absolute inset-0 top-1 left-1 text-primary;
}

input[type="checkbox"]:checked:after {
  content: url("https://api.iconify.design/akar-icons/check.svg?color=%23f44c4c");
  @apply absolute inset-0 top-1 left-1 text-primary;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-range,
.react-datepicker__day--in-selecting-range {
  background-color: $primary !important;
}

.react-datepicker {
  border: 1px solid #ddd !important;
  @apply shadow-sm;
}
.react-datepicker__header {
  background-color: rgb(249, 250, 251) !important;

  border-bottom: 1px solid #ddd !important;
}
