@import "../../assets/scss/variables";
@import "../../assets/scss/mixins";
.button {
  width: 78px;
  height: 78px;
  border: 2px solid #151b1f;
}
.logo {
  margin-top: 35px;
  margin-bottom: 35px;
  margin-left: auto;
  margin-right: auto;
}
.colfix {
  display: inline-flex;
  & > :first-child {
    margin-left: 0;
  }
  & > :last-child {
    margin-right: 0;
  }
}

